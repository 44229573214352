<template>
  <main v-if="apidaysData" id="content" role="main" class="overflow-hidden">
    <!-- ========== Hero Section ========== -->
    <div id="apidays-hero-section" class="position-relative z-index-2 bg-img-hero overflow-hidden" :class="apidaysData && apidaysData.hero_bg_class ? apidaysData.hero_bg_class : ''">
      <!-- Breadcrumb -->
      <div class="container mt-md-6 pl-0">
        <div class="row justify-content-sm-between mb-5">
          <div class="col-md">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-md-3 mb-0">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'home', params: { lang: $route.params.lang }}">{{ $t('CC01-03-08') }}</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'community-updates-overview', params: { lang: $route.params.lang }}">{{ $t('M01b-03') }}</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page"><a class="disabled">{{ $t(apidaysData.event_title) }}</a></li>
              </ol>
            </nav>
          </div>
          <!-- <div v-if="apidaysData.switchLanguage" class="col-md-auto text-md-right mt-2 ml-3 justify-content-end">
            <button class="btn btn-soft-primary btn-xs language-switch-btn" @click="handleSetLanguage()">{{ switchLanguage }} <i class="fas fa-exchange-alt" /></button>
          </div> -->
        </div>
      </div>
      <!-- Title -->
      <div v-if="apidaysData.header" class="container space-bottom-2 position-relative">
        <div class="w-100 w-md-75 w-lg-60 mx-auto text-center">
          <span class="d-block small font-weight-bold text-cap mb-2">{{ eventDate }}</span>
          <h1>{{ $t(apidaysData.header.heading) }}</h1>
          <h3>{{ $t(apidaysData.header.description) }}</h3>
          <img v-if="apidaysData.header.bg_image_path" class="img-fluid mt-5 header-image" :src="`/assets/${apidaysData.header.bg_image_path}`">
          <h4 v-if="apidaysData.header.bg_image_description" class="mt-3 mb-0">{{ $t(apidaysData.header.bg_image_description) }}</h4>
        </div>
        <div v-if="apidaysData.header && apidaysData.header.video" class="w-lg-75 mx-lg-auto pt-9">
          <div class="card">
            <VideoSection id="VideoPlayer" :coverImage="apidaysData.header.video.cover_image_path" :options="apidaysData.header.video.options" />
          </div>
          <p v-if="apidaysData.header.video && apidaysData.header.video.caption" class="text-center font-size-1 mt-2">{{ apidaysData.header.video.caption }}</p>
        </div>
        <div v-if="apidaysData.call_to_action || apidaysData.subscribe_to_action" class="row pt-9">
          <div v-if="apidaysData.call_to_action" class="mb-3 mb-lg-0" :class="apidaysData.subscribe_to_action ? 'col-lg-6' : 'col-lg-12'">
            <div class="card bg-primary text-white h-100 overflow-hidden p-5">
              <div class="w-75 action-banner">
                <h3 class="text-white mb-3">{{ $t(apidaysData.call_to_action.banner.label) }}</h3>
                <p v-if="apidaysData.call_to_action.banner.description" class="text-white">{{ $t(apidaysData.call_to_action.banner.description) }}</p>
                <div>
                  <router-link :id="apidaysData.call_to_action.button.id" class="btn btn-sm btn-light" :to="recapUrl">
                    {{ $t(apidaysData.call_to_action.button.label) }} <i class="fas fa-angle-right ml-1" />
                  </router-link>
                </div>
              </div>
              <div class="position-absolute right-0 bottom-0 w-35 mb-n3 mr-n4">
                <img class="img-fluid" src="@/assets/svg/illustrations/event-resources.svg">
              </div>
            </div>
          </div>
          <div v-if="apidaysData.subscribe_to_action" :class="apidaysData.call_to_action ? 'col-lg-6' : 'col-lg-12'">
            <div class="card bg-secondary text-white h-100 overflow-hidden p-5">
              <div class="w-75 action-banner">
                <h3 class="text-white mb-3">{{ $t(apidaysData.subscribe_to_action.banner.label) }}</h3>
                <p v-if="apidaysData.subscribe_to_action.banner.description" class="text-white">{{ $t(apidaysData.subscribe_to_action.banner.description) }}</p>
                <div>
                  <button :id="apidaysData.subscribe_to_action.button.id" class="btn btn-sm btn-light" @click="handleCtaButton(apidaysData.subscribe_to_action.button.action)">{{ $t(apidaysData.subscribe_to_action.button.label) }} <i class="fas fa-angle-right ml-1" /></button>
                </div>
              </div>
              <div class="position-absolute right-0 bottom-0 w-35 mb-n3 mr-n4">
                <img class="img-fluid" src="@/assets/svg/illustrations/communication.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Organiser Section ========== -->
    <OrganiserBar :title="apidaysData.event_title" :list="apidaysData.organiser_list" />
    <!-- ========== End Organiser Section ========== -->

    <!-- ========== Participants Section ========== -->
    <DefaultSection
      v-if="apidaysData.participants"
      :id="apidaysData.participants && apidaysData.participants.id"
      :label="$t('ApidaysGeneral.Participants.SectionLabel')"
      :heading="apidaysData.participants && apidaysData.participants.heading ? $t(apidaysData.participants.heading) : ''"
      :image="apidaysData.participants && apidaysData.participants.images ? apidaysData.participants.images : []"
      :container="apidaysData.participants && apidaysData.participants.containerCss ? apidaysData.participants.containerCss : ''"
      :headerContainerCss="apidaysData.participants && apidaysData.participants.headerContainerCss ? apidaysData.participants.headerContainerCss : ''"
    />
    <!-- ========== End Participants Section ========== -->

    <!-- ========== About Section ========== -->
    <div v-if="apidaysData.about" class="container space-2">
      <div class="row justify-content-lg-between">
        <div class="col-lg-5 order-lg-2 pl-lg-0" data-aos="fade-left">
          <div id="apidays-about-section" class="bg-img-hero h-100 min-h-380rem" />
        </div>
        <div class="col-lg-6 order-lg-1">
          <div class="pt-8 pt-lg-0">
            <div class="mb-5 mb-md-7">
              <span class="d-block small font-weight-bold text-cap mb-2">{{ $t('ApidaysGeneral.About.SectionLabel') }}</span>
              <h2 class="mb-3">{{ $t(apidaysData.about.heading) }}</h2>
              <template v-if="apidaysData.about.description">
                <p v-for="(text, i) in apidaysData.about.description" :key="i">{{ $t(text) }}</p>
              </template>
              <template v-if="apidaysData.about.points">
                <div v-for="(point, i) in apidaysData.about.points" :key="i" class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    <p>{{ $t(point) }}</p>
                  </div>
                </div>
              </template>

              <div class="text-center">
                <h4>{{ $t(apidaysData.about.cta) }}</h4>
              </div>
            </div>

            <div class="text-center">
              <a v-if="apidaysData.about.button" id="ga-event-apidays-2021-feedback-button" class="btn btn-primary mb-3 mb-sm-0 mx-2" :href="apidaysData.about.button.link" target="_blank">{{ $t(apidaysData.about.button.label) }}</a>
              <a v-if="apidaysData.about.apidays_site" id="ga-event-apidays-2021-globalsite-button" class="btn btn-outline-primary mb-3 mb-sm-0 mx-2" :href="apidaysData.about.apidays_site" target="_blank">{{ $t('ApidaysGeneral.About.ApidaysGlobalSite') }}</a>
            </div>
          </div>
        </div>
      </div>
      <!-- More features -->
      <div v-if="apidaysData.about.statistics" class="row mt-11">
        <div v-for="(item, s) in apidaysData.about.statistics" :key="s" class="col-md-3 col-6 mb-3 mb-md-5">
          <div class="pr-lg-4">
            <span class="h2 text-primary">{{ item.value }}</span>
            <p>{{ $t(item.description) }}</p>
          </div>
        </div>
      </div>
      <!-- End More features -->
    </div>
    <!-- ========== End About Section ========== -->

    <!-- ========== Speakers Section ========== -->
    <div class="bg-light space-2">
      <div class="container">
        <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-5 mb-md-9">
          <span class="d-block small font-weight-bold text-cap mb-2">{{ $t('ApidaysGeneral.Speakers.SectionLabel') }}</span>
          <h2>{{ $t('ApidaysGeneral.Speakers.Heading') }}</h2>
        </div>
        <!-- Filters -->
        <template v-if="apidaysData && apidaysData.speaker_list && apidaysData.speaker_list.length > 1">
          <input v-for="list in apidaysData.speaker_list" :id="list.group" :key="list.group" v-model="selected_group" type="radio" name="categories" :value="list.group">

          <ol class="filters-btn label-list">
            <li v-for="list in apidaysData.speaker_list" :key="list.group">
              <label class="btn btn-sm btn-soft-secondary btn-pill py-2 px-3 m-1 group-filter" :class="{'selectedGroup': selected_group === list.group}" :for="list.group">
                <span v-if="list.title_label">{{ $t(list.title_label) }}</span>
                <span v-else>{{ $t(`ApidaysLiveHongKong2020Overview.Speakers.${list.title.replace(' ', '')}`) }}</span>
              </label>
            </li>
          </ol>
        </template>
        <!-- End Filters -->
        <span v-for="list in apidaysData.speaker_list" :key="`${list.group}_speaker`">
          <ol v-if="list.group === selected_group" class="row">
            <!-- Speaker -->
            <li v-for="speaker in list.speaker" :key="speaker.name" class="col-12 col-sm-6 col-lg-3 px-2 mb-3 filters-item align-items-stretch" :data-category="list.group">
              <SpeakerCard :speaker="speaker" />
            </li>
            <!-- End Speaker -->
          </ol>
        </span>
      </div>
    </div>
    <!-- ========== End Speakers Section ========== -->

    <!-- ========== Agenda Section ========== -->
    <DefaultSection
      v-if="apidaysData.schedule"
      :id="apidaysData.schedule && apidaysData.schedule.id"
      :label="$t('ApidaysGeneral.Schedule.SectionLabel')"
      :heading="`${$t(apidaysData.event_title)} ${$t('ApidaysGeneral.Schedule.Heading')}`"
      :image="apidaysData.schedule && apidaysData.schedule.images ? apidaysData.schedule.images : []"
      :container="apidaysData.schedule && apidaysData.schedule.containerCss ? apidaysData.schedule.containerCss : ''"
      :headerContainerCss="apidaysData.schedule && apidaysData.schedule.headerContainerCss ? apidaysData.schedule.headerContainerCss : ''"
    />
    <!-- ========== End Agenda Section ========== -->
    <!-- ========== Download Media Kit Section ========== -->
    <div v-if="apidaysData.resources" class="gradient-y-sm-primary">
      <div class="container space-2" data-aos="fade-up">
        <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-5">
          <span class="d-block small font-weight-bold text-cap mb-2">{{ $t('ApidaysLiveHongKong2020Overview.Resources.SectionLabel') }}</span>
          <h2>{{ $t('ApidaysLiveHongKong2020Overview.Resources.Heading') }}</h2>
        </div>
        <div class="text-center">
          <a id="ga-event-apidays-2020-media-kit-button" :href="apidaysData.resources" class="btn btn-primary" download>
            <i class="fas fa-download mr-1" /> {{ downloadMediaKitLabel }}
          </a>
        </div>
      </div>
    </div>
    <!-- ========== End Download Media Kit Section ========== -->
    <Sponsors :title="apidaysData.event_title" :sponsorList="apidaysData.sponsor_list" />

    <!-- ========== Gallery Section ========== -->
    <div v-if="apidaysData.gallery_list && apidaysData.gallery_list.images" class="space-2">
      <div class="position-relative">
        <div class="container space-1 position-relative z-index-2 ">
          <div class="w-md-80 w-lg-80 text-center mx-md-auto mb-9">
            <span class="d-block small font-weight-bold text-cap mb-2">{{ $t('ApidaysGeneral.Gallery.SectionLabel') }}</span>
            <h2>{{ galleryTitle }}</h2>
          </div>
          <CoolLightBox :items="galleryImg" :index="index" :useZoomBar="true" slideshowColorBar="#57ABF0" :slideshowDuration="7000" @close="index = null" />
          <div class="row">
            <div v-for="(item, i) in apidaysData.gallery_list.images" :key="i" class="col-12 px-2 transition-3d-hover" :class="item.class">
              <div :id="`apidays-gallery-list-${i}`" class="img-fluid rounded h-250rem bg-img-hero shadow-soft cursor-pointer" data-aos="fade-up" :data-aos-delay="item.data_aos_delay" @click="index = i" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Gallery Section ========== -->
    <div class="space-1" />
    <b-modal id="e-newsletter-dialog" ref="e-newsletter-dialog" centered hide-footer>
      <template slot="modal-header-close">
        <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" aria-label="Close">
          <img :src="require(`@/assets/svg/icons/icon-68.svg`)">
        </button>
      </template>
      <div class="text-center">
        <h3>Be the First to Know</h3>
        <p>Subscribe to our e-newsletter to be the first to receive our latest news, promotions and events.</p>
      </div>
      <div id="hubspotENewsletterForm" />
    </b-modal>
  </main>
</template>

<script>
const $ = require('jquery')
import { mapGetters } from 'vuex'
import OrganiserBar from '@/components/organiser-bar'
import DefaultSection from '../components/default-section'
import Sponsors from '../components/sponsors'
import VideoSection from '../components/video-section'
import SpeakerCard from '@/components/speaker-card'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { formatName } from '@/utils/format-util'
import { apidaysEventDate, getYYYYMMDD } from '@/utils/date-time-util'
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'

export default {
  name: 'ApidaysOverview',
  components: {
    OrganiserBar,
    DefaultSection,
    Sponsors,
    VideoSection,
    SpeakerCard,
    CoolLightBox
  },
  data() {
    return {
      selected_group: '',
      index: null
    }
  },
  computed: {
    ...mapGetters([
      'apidaysTitle', 'apidaysData', 'language'
    ]),
    galleryImg() {
      if (!this.apidaysData.gallery_list || !this.apidaysData.gallery_list.images) return []
      else {
        return (this.apidaysData.gallery_list.images.map(img => {
          return {
            title: this.$t(this.apidaysData.event_title),
            src: `/assets/${this.apidaysData.gallery_list.image_path}/${img.src}`
          }
        }))
      }
    },
    eventDate() {
      return this.apidaysData ? apidaysEventDate(this.apidaysData.startTime, this.apidaysData.endTime, this.language) : null
    },
    lang() {
      return this.$i18n.locale
    },
    switchLanguage() {
      return this.$i18n.locale === 'en-us' ? '切換繁體中文' : 'Change to English Version'
    },
    recapUrl() {
      var formattedStartDate = ''
      if (this.apidaysData.startTime && getYYYYMMDD(this.apidaysData.startTime)) {
        formattedStartDate = getYYYYMMDD(this.apidaysData.startTime)
      } else if (this.apidaysData.date && getYYYYMMDD(event.compareTime)) {
        formattedStartDate = getYYYYMMDD(this.apidaysData.compareTime)
      } else {
        formattedStartDate = getYYYYMMDD(this.apidaysData.date)
      }
      return this.apidaysData ? `/${this.$route.params.lang}/member/community-updates/${formattedStartDate}-${formatName(this.apidaysData.title)}/resources` : ''
    },
    galleryTitle() {
      // if (this.$i18n.locale === 'en-us' || (this.apidaysData && !this.apidaysData.switchLanguage)) {
      if (this.$i18n.locale === 'en-us') {
        return `${this.$t('ApidaysGeneral.Gallery.Heading')} ${this.$t(this.apidaysData.event_title)}`
      } else {
        return `${this.$t(this.apidaysData.event_title)}${this.$t('ApidaysGeneral.Gallery.Heading')}`
      }
    },
    downloadMediaKitLabel() {
      if (this.$i18n.locale === 'en-us' || (this.apidaysData && !this.apidaysData.switchLanguage)) {
        return `${this.$t('General.Download')} ${this.$t('ApidaysLiveHongKong2020Overview.Resources.Heading')}`
      } else {
        return `${this.$t('General.Download')}${this.$t('ApidaysLiveHongKong2020Overview.Resources.Heading')}`
      }
    }
  },
  watch: {
    apidaysData() {
      this.selected_group = this.apidaysData.selected_group
    }
  },
  beforeDestroy() {
    window.document.styleSheets[0].deleteRule(window.document.styleSheets[0].cssRules.length - 1)
    window.document.styleSheets[0].deleteRule(window.document.styleSheets[0].cssRules.length - 1)
    if (this.apidaysData.gallery_list && this.apidaysData.gallery_list.images) {
      this.apidaysData.gallery_list.images.map(item => {
        window.document.styleSheets[0].deleteRule(window.document.styleSheets[0].cssRules.length - 1)
      })
    }
  },
  created() {
    this.selected_group = this.apidaysData.selected_group
    // set to en-us if the event does not have chinese translations
    //  TODO - temporarily disabled this.
    if (!this.apidaysData.switchLanguage) {
      // this.$i18n.locale = 'en-us'
      // this.$store.dispatch('app/setLanguage', 'en-us')
    }
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    var sheet = window.document.styleSheets[0]
    sheet.insertRule(
      `#apidays-hero-section {
        background-image: url(/assets/${this.apidaysData.hero_bg_image_path})
      }`,
      sheet.cssRules.length
    )
    sheet.insertRule(
      `#apidays-about-section {
        background-image: url(/assets/${this.apidaysData.about.image})
      }`,
      sheet.cssRules.length
    )
    if (this.apidaysData.gallery_list && this.apidaysData.gallery_list.images) {
      this.apidaysData.gallery_list.images.map((item, i) => {
        sheet.insertRule(
          `#apidays-gallery-list-${i} {
            background-image: url(/assets/${this.apidaysData.gallery_list.image_path}/${item.src})
          }`,
          sheet.cssRules.length
        )
      })
    }
  },
  methods: {
    handleSetLanguage() {
      this.$i18n.locale = this.lang
      this.$store.dispatch('app/setLanguage', this.lang)
    },
    handleCtaButton(action) {
      if (action.type === 'link') {
        window.open(action.link, '_blank')
      } else if (action.type === 'hubspot') {
        this.showENewsletterDialog(action.form_id)
      }
    },
    showENewsletterDialog(form_id) {
      const script = document.createElement('script')
      script.src = 'https://js.hsforms.net/forms/v2.js'
      document.body.appendChild(script)
      script.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: 'na1',
            portalId: '7091000',
            formId: form_id,
            errorClass: 'hubspotENewsletterFormError',
            target: `#hubspotENewsletterForm`
          })
        }
      })
      this.$nextTick(() => {
        this.$refs['e-newsletter-dialog'].show()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.selectedGroup, .group-filter:hover {
  background: #1c344c !important;
  color: #fff !important;
}
.language-switch-btn {
  color: #57abf0;
  background-color: rgba(55,125,255,.1);
  border-color: transparent;
  &:hover {
    color: #fff;
    background-color: #57abf0;
    box-shadow: 0 4px 11px rgba(55,125,255,10%);
  }
}
.action-banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.modal-body {
  padding: 0px !important;
  .text-center {
    text-align: center;
  }
}
.btn-light:hover {
  box-shadow:0 4px 11px rgba(247,250,255,0.35);
}
.label-list {
  margin-left: 1rem;
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.header-image {
  max-width:15rem
}
</style>

