<template>
  <div class="bg-light">
    <div class="container space-top-2 space-bottom-1">
      <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-9">
        <span class="d-block small font-weight-bold text-cap mb-2">{{ $t('ApidaysGeneral.Sponsors.SectionLabel') }}</span>
        <h2>{{ $t(title) }} {{ $t('ApidaysGeneral.Sponsors.Heading') }}</h2>
      </div>

      <div v-for="list in sponsorList" :key="list.type" class="mb-9">
        <div class="text-center">
          <span class="divider mb-2 lead font-weight-bold">{{ $t(`ApidaysGeneral.Sponsors.${list.type}`) }}</span>
        </div>
        <div class="row text-center align-items-center" :class="list.css_class">
          <div v-for="sponsor in list.sponsor" :key="sponsor.image_url" class="col-12" :class="sponsor.css_class">
            <img class="img-fluid" :class="sponsor.image_css" :src="`/assets/events/apidays-live-hongkong/${sponsor.image_url}`">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sponsors',
  props: {
    title: {
      type: String,
      default: ''
    },
    sponsorList: {
      type: Array,
      default: () => []
    }
  }
}
</script>
