<template>
  <div>
    <ErrorPage v-if="error404occured" />
    <template v-else>
      <template v-if="loading">
        <SkeletonUI type="empty" />
      </template>
      <template v-else>
        <template v-if="eventInfo.isCampaignEvent">
          <div id="stickyBlockStartPoint" class="col-lg-5 col-xl-4 position-relative z-index-2" />
          <div
            class="js-sticky-block"
            data-hs-sticky-block-options="{
            &quot;parentSelector&quot;: &quot;#stickyBlockStartPoint&quot;,
            &quot;breakpoint&quot;: &quot;lg&quot;,
            &quot;startPoint&quot;: &quot;#stickyBlockStartPoint&quot;,
            &quot;endPoint&quot;: &quot;#stickyBlockEndPoint&quot;,
            &quot;stickyOffsetTop&quot;: 20,
            &quot;stickyOffsetBottom&quot;: 12
            }"
          />
          <component :is="eventInfo.eventTemplate" />
          <div id="stickyBlockEndPoint" />
        </template>
        <template v-else>
          <div class="event-details">
            <div class="container space-lg-1 pl-0">
              <div aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'home', params: { lang: $route.params.lang }}">{{ $t('CC01-03-08') }}</router-link>
                  </li>
                  <li class="breadcrumb-item" aria-current="page">
                    <router-link :to="{ name: 'community-updates-overview', params: { lang: $route.params.lang }}">{{ $t('M01b-03') }}</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><a class="disabled">{{ eventInfo.title }}</a></li>
                </ol>
              </div>
            </div>
            <div class="position-relative">
              <!-- ========== Event Banner ========== -->
              <div class="container">
                <div class="row">
                  <div class="col-lg-7 col-xl-8 pr-lg-5">
                    <div class="position-relative">
                      <div v-if="eventStatus && eventStatus.value === 'ended'" class="alert alert-soft-secondary small mb-3 text-center">
                        {{ eventInfo.more_card ? $t('M01ci-04a-01') : $t('M01ci-04a-02') }}
                      </div>
                      <img class="card-img-top shadow-sm" :src="`/assets/events/${eventInfo.image_url}`" :alt="eventInfo.title">
                    </div>
                  </div>
                </div>
              </div>
              <!-- ========== End Event Banner ========== -->

              <!-- ========== Sidebar Content Section ========== -->
              <div class="container position-lg-absolute top-0 right-0 left-0">
                <div class="row justify-content-end">
                  <div id="stickyBlockStartPoint" class="col-lg-5 col-xl-4 position-relative z-index-2">
                    <div
                      class="js-sticky-block"
                      data-hs-sticky-block-options="{
                         &quot;parentSelector&quot;: &quot;#stickyBlockStartPoint&quot;,
                         &quot;breakpoint&quot;: &quot;lg&quot;,
                         &quot;startPoint&quot;: &quot;#stickyBlockStartPoint&quot;,
                         &quot;endPoint&quot;: &quot;#stickyBlockEndPoint&quot;,
                         &quot;stickyOffsetTop&quot;: 20,
                         &quot;stickyOffsetBottom&quot;: 12
                       }"
                    >
                      <div class="card">
                        <div class="card-body">
                          <div class="d-flex justify-content-between align-items-center mb-3">
                            <span class="d-block small font-weight-bold text-cap">{{ $t(eventInfo.category) }}</span>
                          </div>
                          <div class="mb-5">
                            <h4>{{ eventInfo.title }}</h4>
                            <span v-if="eventInfo.organiser">
                              <p class="small mb-0">
                                {{ $t('M01ci-03d-01') }}
                                <span v-for="(item, index) in eventInfo.organiser" :key="item.company">
                                  {{ item.company }}
                                  <span v-if="index != Object.keys(eventInfo.organiser).length - 1">, </span>
                                </span>
                                {{ $t('M01ci-03d-02') }}
                              </p>
                            </span>
                          </div>
                          <!-- Icon Block -->
                          <div v-for="(info, index) in sidebarCard" :key="index" class="media text-body font-size-1 mb-2">
                            <div class="min-w-3rem text-center mr-3">
                              <i :class="info.icon" />
                            </div>
                            <div class="media-body">{{ info.value }}</div>
                          </div>
                          <!-- End Icon Block -->
                          <div v-if="eventInfo.website" class="mt-4">
                            <a id="ga-webinar-detail-button" class="btn btn-block btn-primary" :href="eventInfo.website" target="_blank">
                              {{ eventInfo.button_text ? $t(eventInfo.button_text) : $t('M01ci-03i-01') }}
                            </a>
                          </div>
                        </div>
                      </div>

                      <router-link v-if="eventInfo.more_card" data-gtm-att="ga-M01ci-06" :to="`/${lang}/member/community-updates/${eventPath}/resources`" class="mt-5 card card-bg-light card-frame text-left h-100 transition-3d-hover w-md-80 w-lg-100 mx-auto">
                        <div v-for="item in eventInfo.more_card" :key="item.title" class="card-body">
                          <h3>{{ $t(item.title) }}</h3>
                          <p>{{ $t(item.description) }}</p>
                          <span class="font-weight-bold">{{ $t(item.button_text) }} <i class="fas fa-angle-right fa-sm ml-1" /></span>
                          <div class="position-absolute bottom-0 right-0 w-lg-35 max-w-27rem">
                            <img class="img-fluid" :src="require('@/assets/svg/illustrations/' + item.image_url)">
                          </div>
                          <div class="mt-2 w-50 w-md-25 float-right d-lg-none">
                            <img class="img-fluid" :src="require('@/assets/svg/illustrations/' + item.image_url)">
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ========== End Sidebar Content Section ========== -->
            </div>

            <!-- ========== Description Section ========== -->
            <div class="container">
              <div class="row">
                <div class="col-lg-7 col-xl-8 pr-lg-5">

                  <!-- Info -->
                  <div class="space-top-2">

                    <div v-if="eventInfo.organiser" class="mb-8">
                      <h3>{{ $t('M01ci-05a') }}</h3>
                      <div class="row justify-content-lg-center">
                        <div v-for="item in eventInfo.organiser" :key="item.company" class="col-6 col-md-3 text-center">
                          <img class="mb-1 organiser-img" :src="`/assets/events/${item.image_url}`">
                        </div>
                      </div>
                    </div>

                    <div v-if="eventInfo.about" class="mb-8">
                      <h3>{{ $t('M01ci-05c', { event: $t(eventInfo.category) }) }}</h3>
                      <p v-for="item in eventInfo.about" :key="item">{{ item }}</p>
                      <div v-if="eventInfo.about_special" v-html="eventInfo.about_special" />
                    </div>

                    <div v-if="eventInfo.agenda" class="mb-8">
                      <h3>{{ $t('M01ci-05e') }}</h3>
                      <ul>
                        <li v-for="item in eventInfo.agenda" :key="item" class="mb-3">
                          <span>{{ item }}</span>
                        </li>
                      </ul>
                    </div>

                    <div v-if="eventInfo.host" class="mb-8">
                      <h3>{{ $t('M01ci-05g') }}</h3>
                      <ul>
                        <li v-for="item in eventInfo.host" :key="item.name" class="mb-4">
                          <span class="d-block">{{ item.name }}</span>
                          <span class="d-block text-body font-size-1">{{ item.job_title }}</span>
                          <div class="border-left border-3 pl-4 mt-2">
                            <p>{{ item.bio }}</p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div v-if="eventInfo.moderator" class="mb-8">
                      <h3>{{ $t('M01ci-05m') }}</h3>
                      <ul>
                        <li v-for="item in eventInfo.moderator" :key="item.name" class="mb-4">
                          <span class="d-block">{{ item.name }}</span>
                          <span class="d-block text-body font-size-1">{{ item.job_title }}</span>
                          <div class="border-left border-3 pl-4 mt-2">
                            <p>{{ item.bio }}</p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div v-if="eventInfo.speaker" class="mb-8">
                      <h3>{{ $t('M01ci-05i') }}</h3>
                      <ul>
                        <li v-for="item in eventInfo.speaker" :key="item.name" class="mb-4">
                          <span class="d-block">{{ item.name }}</span>
                          <span class="d-block text-body font-size-1">{{ item.job_title }}</span>
                          <div class="border-left border-3 pl-4 mt-2">
                            <p>{{ item.bio }}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div v-if="eventInfo.organiser_about" class="mb-8">
                    <h3>{{ $t('M01ci-05k') }}</h3>
                    <ul>
                      <div v-if="eventInfo.organiser_about" v-html="eventInfo.organiser_about" />
                    </ul>
                  </div>

                  <div v-if="eventInfo.website" class="d-lg-none space-top-1 text-center w-md-80 mx-auto" data-aos="fade-up">
                    <a id="ga-webinar-detail-button" class="btn btn-block btn-primary" :href="eventInfo.website" target="_blank">{{ eventInfo.button_text ? $t(eventInfo.button_text) : $t('M01ci-03i-01') }}</a>
                  </div>

                  <div class="d-lg-none space-1 w-md-80 mx-auto">
                    <router-link v-if="eventInfo.more_card" data-gtm-att="ga-M01ci-06" :to="`/${lang}/member/community-updates/${eventPath}/resources`" class="mt-5 card card-bg-light card-frame text-left h-100 transition-3d-hover">
                      <div v-for="item in eventInfo.more_card" :key="item.title" class="card-body">
                        <h3>{{ $t(item.title) }}</h3>
                        <p>{{ $t(item.description) }}</p>
                        <span class="font-weight-bold">{{ $t(item.button_text) }} <i class="fas fa-angle-right fa-sm ml-1" /></span>
                        <div class="mt-2 w-50 w-md-25 float-right">
                          <img class="img-fluid" :src="require(`@/assets/svg/illustrations/${item.image_url}`)">
                        </div>
                      </div>
                    </router-link>
                  </div>

                </div>
                <!-- End Info -->

              </div>
            </div>
            <!-- ========== End Description Section ========== -->

            <!-- Sticky Block End Point -->
            <div id="stickyBlockEndPoint" />

            <div class="space-1" />

            <div v-if="eventInfo.gallery" id="community-update-hero-section" class="bg-img-hero">
              <div class="container space-2">
                <h3>Gallery</h3>
                <div>
                  <Slick ref="gallerySlick" :options="slickOptions">
                    <div v-for="item in eventInfo.gallery" :key="item.image_url" class="js-slide">
                      <img class="img-fluid" :src="`/assets/events/webinars/gallery/${item.image_url}`">
                    </div>
                  </Slick>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
const $ = require('jquery')
import Slick from 'vue-slick'
import ErrorPage from '@/views/error-page/index'
import { mapGetters } from 'vuex'
import { formatName, getEventStatus } from '@/utils/format-util'
import { getDate, getTime, getYYYYMMDD } from '@/utils/date-time-util'
import HSStickyBlock from '@/assets/vendor/hs-sticky-block/src/js/hs-sticky-block'
import ApiEcobooster from './event-template/api-ecobooster.vue'
import ApidaysOverview from './event-template/apidays-overview.vue'
import SkeletonUI from '@/components/skeleton-ui'

export default {
  name: 'CommunityUpdatesDetails',
  components: {
    ErrorPage,
    Slick,
    ApiEcobooster,
    ApidaysOverview,
    SkeletonUI
  },
  data() {
    return {
      event_id: '',
      eventInfo: {},
      loading: false,
      error404occured: false,
      slickOptions: {
        initialSlide: 0,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        dots: true,
        dotsClass: 'slick-pagination d-flex justify-content-center d-lg-none mt-3',
        slidesToShow: 3,
        prevArrow: '<span class="fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-n3" />',
        nextArrow: '<span class="fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-n3" />',
        centerMode: true,
        centerPadding: 0,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              dots: false
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      'events', 'apidaysTitle', 'apidaysData'
    ]),
    lang() {
      return this.$route.params.lang
    },
    sidebarCard() {
      var cardInfo = [
        { icon: 'far fa-calendar-minus', value: this.eventInfo.startTime ? getDate(this.eventInfo.startTime, this.eventInfo.endTime) : this.eventInfo.date },
        { icon: 'fas fa-map-marker-alt', value: this.eventInfo.location },
        { icon: 'fas fa-globe', value: this.eventInfo.language },
        { icon: 'far fa-credit-card', value: this.eventInfo.fee === 0 ? 'Free' : this.eventInfo.fee }
      ]
      if (this.eventInfo.startTime) {
        cardInfo.splice(2, 0, { icon: 'far fa-clock', value: `${getTime(this.eventInfo.startTime, this.eventInfo.endTime)} (${this.eventInfo.timezone ? this.eventInfo.timezone : 'HKT'})` })
      }
      return cardInfo
    },
    eventPath() {
      var formattedName = this.eventInfo.title_eng ? formatName(this.eventInfo.title_eng) : formatName(this.eventInfo.title)
      var formattedStartDate = ''
      if (this.eventInfo.startTime && getYYYYMMDD(this.eventInfo.startTime)) {
        formattedStartDate = getYYYYMMDD(this.eventInfo.startTime)
      } else if (this.eventInfo.compareTime && getYYYYMMDD(this.eventInfo.compareTime)) {
        formattedStartDate = getYYYYMMDD(this.eventInfo.compareTime)
      } else {
        formattedStartDate = getYYYYMMDD(this.eventInfo.date)
      }
      var path = formattedStartDate ? `${formattedStartDate}-${formattedName}` : `${formattedName}`
      return path[path.length - 1] === '-' ? path.substr(0, path.length - 1) : path
    },
    eventStatus() {
      return getEventStatus(this.eventInfo)
    }
  },
  watch: {
    async events() {
      if (this.events.length === 0) {
        await this.$store.dispatch('events/getEventList')
      }
    },
    async $route() {
      this.getEventTitle()
      await this.getEventInfo()
    }
  },
  beforeDestroy() {
    window.document.styleSheets[0].deleteRule(window.document.styleSheets[0].cssRules.length - 1)
  },
  async created() {
    this.getEventTitle()
    await this.getEventInfo()
  },
  mounted() {
    new HSStickyBlock($('.js-sticky-block')).init()
    var sheet = window.document.styleSheets[0]
    sheet.insertRule(
      `#community-update-hero-section {
        background-image: url(${require('@/assets/svg/components/ben-career-hero.svg')})
      }`,
      sheet.cssRules.length
    )
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: `${this.eventInfo.title} | %s`,
      link: [
        { rel: 'canonical', href: `https://www.openapihub.com/${this.$route.path.substring(7)}` }
      ],
      meta: [
        { name: 'description', content: `${this.eventInfo.description}` },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: `${this.eventInfo.title} | OpenAPIHub` },
        { property: 'og:description', content: `${this.eventInfo.description}` },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: `https://www.openapihub.com/${this.$route.path.substring(7)}` }
      ]
    }
  },
  methods: {
    getEventTitle() {
      this.event_title = this.$route.params.eventTitle
    },
    async getEventInfo() {
      this.error404occured = false
      this.loading = true
      const event_title = this.event_title
      if (this.events.length === 0) {
        await this.$store.dispatch('events/getEventList')
      }
      const filteredEvents = this.events.filter((event) => {
        var formattedName = event.title_eng ? formatName(event.title_eng) : formatName(event.title)
        var formattedStartDate = ''
        if (event.startTime && getYYYYMMDD(event.startTime)) {
          formattedStartDate = getYYYYMMDD(event.startTime)
        } else if (event.date && getYYYYMMDD(event.compareTime)) {
          formattedStartDate = getYYYYMMDD(event.compareTime)
        } else {
          formattedStartDate = getYYYYMMDD(event.date)
        }
        var title = formattedStartDate ? `${formattedStartDate}-${formattedName}` : `${formattedName}`
        title[title.length - 1] === '-' ? title = title.substr(0, title.length - 1) : null
        return title === event_title
      })
      if (filteredEvents.length > 0) {
        this.eventInfo = filteredEvents[0]
        //  Changed on Jan 27 - Removed title for static i18n
        // document.title = `${this.eventInfo.title} | ${this.site_info[this.$i18n.locale].name}`
        if (this.eventInfo.eventTemplate === 'ApidaysOverview' && this.apidaysTitle !== this.eventInfo.title) {
          await this.$store.dispatch('apidays/getEventData', this.eventInfo.title)
        }
        this.loading = false
      } else {
        this.error404occured = true
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
.text-body {
  color: #677788 !important;
}
.organiser-img {
  max-width: 8rem;
}
</style>
