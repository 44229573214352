<template>
  <div :class="containerCss ? containerCss : 'container space-2'">
    <div :class="headerContainerCss ? headerContainerCss : 'w-md-80 w-lg-60 text-center mx-md-auto mb-5'">
      <span class="d-block small font-weight-bold text-cap mb-2">{{ label }}</span>
      <h2>{{ heading }}</h2>
    </div>
    <CoolLightBox :id="id ? id : ''" :items="lightboxImage" :index="index" @close="index = null" />
    <div :class="image.length > 1 ? 'row' : ''">
      <div v-for="(img, i) in image" :key="i" class="cursor-pointer" :class="img.css_class">
        <img
          :alt="img.alt"
          class="img-fluid rounded shadow cursor-pointer"
          :data-aos="img.data_aos ? img.data_aos : ''"
          :src="`/assets/${img.src}`"
          @click="index = i"
        >
      </div>
    </div>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'DefaultSection',
  components: {
    CoolLightBox
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    heading: {
      type: String,
      default: ''
    },
    image: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {
        return {
          speed: 700
        }
      }
    },
    containerCss: {
      type: String,
      default: ''
    },
    headerContainerCss: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      index: null
    }
  },
  computed: {
    lightboxImage() {
      if (this.image.length === 0) return []
      else {
        return (this.image.map(img => {
          return {
            title: this.$t(img.title),
            alt: this.$t(img.alt),
            src: `/assets/${img.src}`
          }
        }))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
