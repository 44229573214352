<template>
  <div class="container space-2" :class="list.css">
    <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-9">
      <span class="d-block small font-weight-bold text-cap mb-2">{{ $t('ApidaysGeneral.Organizers.SectionLabel') }}</span>
      <h2>{{ $t(label) }}</h2>
    </div>

    <div class="row justify-content-lg-center">
      <div v-for="item in list.organiser" :key="item.image_url" class="mb-9 mb-md-0" data-aos="fade-up" :data-aos-delay="item.data_aos_delay" :class="(list.organiser.length === 2 ? 'col-md-6' : 'col-md-4')">
        <div class="d-block text-center" :class="item.css">
          <h4 class="mb-2">{{ $t(`ApidaysGeneral.Organizers.${item.post}`) }}</h4>
          <img class="organizer-img" :src="`/assets/events/${list.image_path}/organisers/${item.image_url}`">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OrganiserBar',
  components: {
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Object,
      default: () => {
        return {
          image_path: 'apidays-live-hongkong',
          css: '',
          organiser: []
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'apidaysTitle', 'apidaysData', 'language'
    ]),
    label() {
      return `${this.$t(this.title)} ${this.$t('ApidaysGeneral.Organizers.Heading')}`
    }
  }
}
</script>

<style lang="scss" scoped>
.organizer-img {
  max-width: 12rem;
}
</style>
