// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-3!slick-carousel/slick/slick.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-3!slick-carousel/slick/slick-theme.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".text-body[data-v-6d73d358]{color:#677788!important}.organiser-img[data-v-6d73d358]{max-width:8rem}", ""]);
// Exports
module.exports = exports;
