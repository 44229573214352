<template>
  <div class="h-100">
    <div class="card h-100 transition-3d-hover cursor-pointer" @click="showSpeakerPopup">
      <div class="card-body">
        <div class="avatar avatar-xl avatar-circle mb-4 card-img">
          <img class="avatar-img" :src="`/assets/events/apidays-live-hongkong/${speaker.image_url}`">
        </div>
        <h3>{{ speaker.name }}</h3>
        <p>{{ speaker.job_title }}</p>
      </div>
    </div>
    <!-- Modal -->
    <b-modal
      :id="speaker.data_target"
      ref="speakerPopup"
      centered
      hide-footer
      header-class="speaker-card-header"
      class="speaker-dialog"
    >
      <template #modal-header="{ close }">
        <div class="modal-top-cover bg-primary text-center">
          <!-- Header -->
          <figure class="position-absolute right-0 bottom-0 left-0">
            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
              <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
            </svg>
          </figure>
          <figure class="w-100 position-absolute bottom-0 left-0">
            <img class="img-fluid" src="@/assets/svg/components/isometric-lego.svg">
          </figure>
          <div class="modal-close">
            <button type="button" class="btn btn-icon btn-sm btn-ghost-light" aria-label="Close" @click="close()">
              <svg width="16" height="16" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
        </div>
        <!-- End Header -->

        <!-- Speaker Profile  -->
        <div class="modal-top-cover-avatar">
          <img class="avatar avatar-xl avatar-circle avatar-border-lg avatar-centered shadow-soft" :src="`/assets/events/apidays-live-hongkong/${speaker.image_url}`">
        </div>
        <div class="text-center m-3 mx-5">
          <h2 class="mb-0">{{ speaker.name }}</h2>
          <p v-if="speaker.company" class="d-block font-weight-bold mb-1">{{ speaker.company }}</p>
          <p>{{ speaker.position }}</p>
        </div>
      </template>
      <template #default="{ hide }">
        <!-- Body -->
        <div class="modal-body pb-sm-5 px-sm-5">
          <div v-if="speaker.about" class="row mb-6">
            <div class="col-12 col-lg-3 mb-1">
              <h5 class="text-cap">about</h5>
            </div>
            <div class="col-12 col-lg-9">
              <p v-for="item in speaker.about" :key="item">
                {{ item }}
              </p>
              <div v-if="speaker.about_special" v-html="speaker.about_special" />
            </div>
          </div>

          <div v-if="speaker.topic_title" class="row mb-6">
            <div class="col-12 col-lg-3 mb-1">
              <h5 class="text-cap">speaking topic</h5>
            </div>
            <div class="col-12 col-lg-9">
              <h3 class="mb-2">{{ speaker.topic_title }}</h3>
              <span v-if="speaker.topic_description">
                <p v-for="item in speaker.topic_description" :key="item">
                  {{ item }}
                </p>
              </span>
              <div v-if="speaker.topic_special" v-html="speaker.topic_special" />
              <div v-if="speaker.topic_title_2" class="mt-6">
                <h3 class="mb-2">{{ speaker.topic_title_2 }}</h3>
                <span v-if="speaker.topic_description_2">
                  <p v-for="item in speaker.topic_description_2" :key="item">
                    {{ item }}
                  </p>
                </span>
                <div v-if="speaker.topic_special_2" v-html="speaker.topic_special_2" />
              </div>
            </div>
          </div>

          <div v-if="(speaker.linkedin || speaker.twitter)" class="row mb-6">
            <div class="col-12 col-lg-3 mb-1">
              <h5 class="text-cap">get connected</h5>
            </div>
            <div class="col-12 col-lg-9">
              <ul class="list-inline mb-0">
                <li v-if="speaker.linkedin" class="list-inline-item">
                  <a class="btn btn-sm btn-icon btn-soft-secondary rounded" :href="'https://www.linkedin.com/in/' + speaker.linkedin + '/'" target="_blank">
                    <i class="fab fa-linkedin" />
                  </a>
                </li>
                <li v-if="speaker.twitter" class="list-inline-item">
                  <a class="btn btn-sm btn-icon btn-soft-secondary rounded" :href="'https://twitter.com/' + speaker.twitter" target="_blank">
                    <i class="fab fa-twitter" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="text-center mt-9">
            <button type="button" class="btn btn-wide btn-primary" data-dismiss="modal" aria-label="Close" @click="hide()">Back to Speaker List</button>
          </div>
        </div>
        <!-- End Body -->
      </template>
    </b-modal>
  <!-- End Modal -->
  </div>
</template>

<script>
export default {
  name: 'SpeakerCard',
  props: {
    speaker: {
      type: Object,
      default: () => {
        return {
          image_url: '',
          name: '',
          job_title: '',
          company: '',
          position: '',
          media_card: '',
          about: [],
          topic_title: '',
          linkedin: ''
        }
      }
    }
  },
  methods: {
    showSpeakerPopup() {
      this.$refs.speakerPopup.show()
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-primary {
  width: 100%;
  background-color: #57abf0!important;
}
.modal-top-cover {
  position: relative;
  overflow: hidden;
  min-height: 8rem;
  border-top-right-radius: 0.4375rem;
  border-top-left-radius: 0.4375rem;
}
.modal-top-cover-avatar {
  position: relative;
  z-index: 2;
  margin-top: -4rem;
}
.modal-close {
  display: block;
  text-align: right;
  padding-right: 8px;
  padding-top: 8px;
}
</style>
<style lang="scss">
.speaker-card-header {
  padding: 0px;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-bottom: 0px;
}
.text-cap {
  color: #999999;
}
.card-img {
  border: 2px solid #F4F5FA;
}
.speaker-dialog {
  padding: 10px !important;
  z-index: 1080;
}
</style>
