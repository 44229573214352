<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- ========== Hero Section ========== -->
    <div id="ecobooster-hero-section" class="gradient-y-overlay-lg-white position-relative z-index-2 bg-img-hero overflow-hidden">
      <!-- Breadcrumb -->
      <div class="container space-lg-1 pl-0">
        <div aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'home', params: { lang: $route.params.lang }}">{{ $t('CC01-03-08') }}</router-link>
            </li>
            <li class="breadcrumb-item" aria-current="page">
              <router-link :to="{ name: 'community-updates-overview', params: { lang: $route.params.lang }}">{{ $t('M01b-03') }}</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page"><a class="disabled">API EcoBooster</a></li>
          </ol>
        </div>
      </div>
      <!-- Title  -->
      <div class="container space-top-1 space-bottom-2 position-relative">
        <div class="w-lg-60 text-center mx-lg-auto">
          <div class="mb-5">
            <span class="d-block small font-weight-bold text-cap mb-2">Embrace New Technology with Open APIs in SMART BANKING</span>
            <h1>API EcoBooster</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Organiser Section ========== -->
    <OrganiserBar :list="organiser_list" />
    <!-- ========== End Organiser Section ========== -->

    <!-- ========== About Section ========== -->
    <div class="container space-2">
      <div class="row justify-content-lg-between">
        <div class="col-lg-5 order-lg-2 pl-lg-0">
          <div id="ecobooster-about-section" class="bg-img-hero h-100 min-h-380rem" />
        </div>

        <div class="col-lg-6 order-lg-1">
          <div class="pt-8 pt-lg-0">
            <div class="mb-5 mb-md-7">
              <span class="d-block small font-weight-bold text-cap mb-2">About the Event</span>
              <h2 class="mb-3">What's API EcoBooster</h2>
              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>To boost the open Application Programming Interface (API) Economy for different industries</p>
                </div>
              </div>

              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>To transform valuable business assets to delightful customer experience and operational efficiency through innovative applications</p>
                </div>
              </div>
            </div>

            <span class="d-block text-center">
              <a class="btn btn-primary" href="https://gaa.info.hkstp.org/en/challenges/api-ecobooster-2020" target="_blank">
                Register Now
              </a>
              <span class="d-block small mt-3">* You will be directed to the official registration site.</span>
            </span>
          </div>
        </div>
      </div>

      <!-- More features -->
      <div class="mt-11">
        <ul class="step step-md step-centered">
          <li v-for="item in feature_list" :key="item.content" class="step-item">
            <div class="step-content-wrapper">
              <span class="step-icon step-icon-soft-primary"><i :class="item.icon" /></span>
              <div class="step-content">
                <p>{{ item.content }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- ========== End About Section ========== -->

    <!-- ========== How it works Section ========== -->
    <div v-for="(section, index) in section_list" :key="section.title" class="bg-light space-2">
      <div class="container">
        <div class="row justify-content-lg-between">
          <div class="col-lg-5 pl-lg-0" :class="section.image_order">
            <div :id="`ecobooster-how-it-work-section-${index}`" class="bg-img-hero h-100 min-h-380rem" />
          </div>

          <div class="col-lg-6" :class="section.content_order">
            <div class="pt-8 pt-lg-0">
              <div class="mb-5 mb-md-7">
                <span class="d-block small font-weight-bold text-cap mb-2">{{ section.title }}</span>
                <h2 class="mb-3">{{ section.header }}</h2>
                <p class="mb-5">{{ section.discription }}</p>

                <div v-for="item in section.list" :key="item" class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    <p>{{ item }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End How it works Section ========== -->

    <!-- ========== Application Steps Section ========== -->
    <div class="container space-2">
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-9">
        <span class="d-block small font-weight-bold text-cap mb-2">Important dates</span>
        <h2>Programme Timeline</h2>
      </div>

      <ul class="step step-md step-centered">
        <li v-for="(item, index) in timeline_list" :key="item.date" class="step-item">
          <div class="step-content-wrapper">
            <span class="step-icon step-icon-soft-primary">{{ index + 1 }}</span>
            <div class="step-content">
              <h3>{{ item.date }}</h3>
              <p>{{ item.event }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- ========== End Application Steps Section ========== -->
  </main>
</template>

<script>
const $ = require('jquery')
import OrganiserBar from '@/components/organiser-bar'
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'

export default {
  name: 'Apidays',
  components: {
    OrganiserBar
  },
  data() {
    return {
      feature_list: [
        {
          icon: 'fas fa-home',
          content: 'Value Business Assets'
        },
        {
          icon: 'fas fa-key',
          content: 'Open API'
        },
        {
          icon: 'fas fa-lightbulb',
          content: 'Developer'
        },
        {
          icon: 'fas fa-mobile-alt',
          content: 'Innovative Solutions'
        },
        {
          icon: 'fas fa-thumbs-up',
          content: 'Delightful Customer & Employee Experience'
        }
      ],
      organiser_list: {
        title: 'API EcoBooster',
        image_path: 'apiecobooster',
        css: '',
        organiser: [
          {
            post: 'Organiser',
            image_url: 'hkstp.png',
            data_aos_delay: 0,
            css: ''
          },
          {
            post: 'Lead Partner',
            image_url: 'hsbc.png',
            data_aos_delay: 150,
            css: 'column-divider-md column-divider-20deg px-md-3'
          },
          {
            post: 'Technology Partner',
            image_url: 'benovelty.png',
            data_aos_delay: 200,
            css: 'column-divider-md column-divider-20deg px-md-3'
          }
        ]
      },
      section_list: [
        {
          image_order: 'order-lg-1',
          image_url: 'payment-1.jpg',
          content_order: 'order-lg-2',
          title: 'How it works',
          header: 'Smart Banking',
          discription: 'Echo with the Open API Framework initiated by local regulator to extend banking footprints to broader use cases:',
          list: [
            'Loan Services',
            'Transactions and Operation',
            'Credit Card',
            'Digital Payment',
            'Customer Record'
          ]
        },
        {
          image_order: 'order-lg-2',
          image_url: 'coding-2.jpg',
          content_order: 'order-lg-1',
          title: 'Why join',
          header: 'Call For Developers',
          discription: 'For qualified developer, you will be offered:',
          list: [
            'HSBC Mentorship Sessions',
            'Technical Clinics',
            'Sandbox for Solution Testing',
            'Exposure to Users of Banking Industry'
          ]
        }
      ],
      timeline_list: [
        {
          date: '14 Aug 2020',
          event: 'Application Deadline'
        },
        {
          date: '4 Sep 2020',
          event: 'Announcement of Shortlisted Proposals'
        },
        {
          date: 'Sep–Oct 2020',
          event: 'Training and Technical Clinics'
        },
        {
          date: 'Oct 2020',
          event: 'Demonstration Day'
        }
      ]
    }
  },
  beforeDestroy() {
    window.document.styleSheets[0].deleteRule(window.document.styleSheets[0].cssRules.length - 1)
    this.section_list.map(section => {
      window.document.styleSheets[0].deleteRule(window.document.styleSheets[0].cssRules.length - 1)
    })
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    var sheet = window.document.styleSheets[0]
    sheet.insertRule(
      `#ecobooster-hero-section, #ecobooster-about-section {
        background-image: url(/assets/events/apiecobooster/images/apiecobooster.jpg)
      }`,
      sheet.cssRules.length
    )
    this.section_list.map((section, index) => {
      sheet.insertRule(
        `#ecobooster-how-it-work-section-${index} {
          background-image: url(/assets/events/apiecobooster/images/${section.image_url})
        }`,
        sheet.cssRules.length
      )
    })
  }
}
</script>
